import React from "react";
import localize from "../../../../redux/translation/localize";

class MainPageBody extends React.Component {
  state = {
    resize: false,
  };

  componentWillMount() {
    window.onresize = () => this.setState({ resize: !this.state.resize });
  }

  renderBottomBody = () => {
    const { innerWidth } = window;

    if (innerWidth > 1000) {
      return null;
    }
    return (
      <div className="bottomBody">
        <div className="mediumCase">
          <div className="bodyImage">
            <div className="image" />
          </div>
        </div>
      </div>
    );
  };

  handleScrollDown = () => {
    const doc = document.getElementById("#bottombody");

    if (doc) {
      window.scrollTo(0, doc.offsetTop);
    }
  };

  renderScrollDown = () => {
    const { t } = this.props;

    return (
      <div className="scrollDown" onClick={this.handleScrollDown}>
        <div className="text">{t("scrollDown")}</div>
        <div className="icon" />
      </div>
    );
  };

  renderUpperBody = () => {
    const { t } = this.props;
    const brands = t("introDescriptionBrands").split(",");

    return (
      <div className="upperBody">
        <div className="container">
          <div className="avatar" />
          <div className="bodyText">
            <div className="logo" />
            <span className="description">
              {t("introDescription")}
              {brands.map((brand, index) => (
                <span className="unbreakable">
                  {brand}
                  {index >= brands.length - 1 ? "" : ","}&nbsp;
                </span>
              ))}
              {t("introDescriptionEnd")}
            </span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="mainPageBody">
        {this.renderUpperBody()}
        {this.renderBottomBody()}
      </div>
    );
  }
}

export default localize()(MainPageBody);
