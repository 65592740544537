export default {
  // Header
  headerTitle: "The World of Shiseido Fragrance Brands in your Pocket!",
  headerDescription:
    "Follow learning sessions from your favourite brands, find out the latest trends and be rewarded for testing your knowledge!",

  headerComing: " ",

  // Intro
  introDescription:
    "Discover Beauty Genius by Shiseido! This app is made only for the Beauty Consultant community of Shiseido fragrances brands:",
  introDescriptionBrands:
    "ISSEY MIYAKE, narciso rodriguez, ZADIG & VOLTAIRE and Serge Lutens.",
  introDescriptionEnd:
    "Beauty Genius is the key to unlocking a world of beauty at your fingertips.",
  scrollDown: "Scroll down",

  // Body
  bodyTitle: "Engage with Your Community",
  bodyDescription:
    "Ask questions to the Beauty Genius community, and chat with your Team.",
  bodyDescription2: " ",
  bodyFooterDescription: " ",

  // Upper
  download_app: "Download the application",
  app_name: "« Beauty Genius by Shiseido »",
  upperDescription: "in the App Store for iOS\n or Google Play for Android.",
  upperDescriptionIOS: "in the App Store.",
  upperDescriptionAndroid: "in Google Play.",

  // Footer
  footerDescription:
    "Download or search for Beauty Genius by Shiseido in the App Store for iOS or Google Play for Android.",
  footerDescriptionIOS:
    "Download or search for Beauty Genius by Shiseido in the App Store.",
  footerDescriptionAndroid:
    "Download or search for Beauty Genius by Shiseido in the Google Play.",
  footerPrivacy:
    "The Beauty Genius by Shiseido app is meant for Beauty Consultants working with Shiseido brands exclusively. Please contact your local Shiseido training team to learn more.",

  termsStart: "Beauty Genius ",
  termsPrivacy: "Privacy Policy",
  termsBetween: " and ",
  termsUse: "Terms of Use",
  termsEnd: ".",

  // Privacy Page
  loading: "Loading...",
};
