export default {
  // Header
  headerTitle: "Die Welt der Shiseido Duftmarken in Ihrer Tasche!",
  headerDescription:
    "Verfolgen Sie die neuen Lern-Inhalte Ihrer Lieblingsmarken. Erfahren Sie mehr über die neuesten Trends und nehmen Sie an unserem attraktiven Belohungsprogramm teil!",

  headerComing: " ",

  // Intro
  introDescription:
    "Entdecken Sie Beauty Genius von Shiseido! Diese App ist ausschließlich für Beauty-Experten der Shiseido Designer Brands gedacht:",
  introDescriptionBrands:
    "ISSEY MIYAKE, Narciso Rodriguez, ZADIG&VOLTAIRE, Serge Lutens.",
  introDescriptionEnd:
    "Mit Beauty Genius steht Ihnen die Welt der Schönheit immer zur Verfügung.",
  scrollDown: "RUNTERSCROLLEN",

  // Body
  bodyTitle: "Tauschen Sie sich mit Ihrer Community aus",
  bodyDescription:
    "Stellen Sie der Beauty Genius Community Fragen und chatten Sie mit Ihrem Team.",
  bodyDescription2: " ",
  bodyFooterDescription: " ",

  // Upper
  download_app: "Laden Sie die App herunter",
  app_name: "« Beauty Genius by Shiseido »",
  upperDescription: "im App Store für iOS\n oder im Google Play für Android.",
  upperDescriptionIOS: "im App Store.",
  upperDescriptionAndroid: "im Google Play.",

  // Footer
  footerDescription:
    "Laden Sie Beauty Genius von Shiseido herunter oder suchen Sie nach Beauty Genius im App Store für iOS oder im Google Play für Android.",
  footerDescriptionIOS:
    "Laden Sie Beauty Genius von Shiseido herunter oder suchen Sie nach Beauty Genius im App Store.",
  footerDescriptionAndroid:
    "Laden Sie Beauty Genius von Shiseido herunter oder suchen Sie nach Beauty Genius im Google Play.",
  footerPrivacy:
    "Die Beauty Genius App von Shiseido ist ausschließlich für Beauty-Experten gedacht, die mit den Shiseido Designer Brands arbeiten. Bitte kontaktieren Sie Ihr lokales Shiseido Trainingsteam, um mehr zu erfahren.",

  termsStart: "Beauty Genius ",
  termsPrivacy: "Datenschutzhinweis",
  termsBetween: " und ",
  termsUse: "Allegemeine Zugangs- Und Nutzungsbedingungen",
  termsEnd: ".",

  // Privacy Page
  loading: "Wird geladen...",
};
