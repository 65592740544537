import React from "react";
import { connect } from "react-redux";

import {
  actions as TranslationActions,
  selectors as TranslationSelectors,
} from "../../redux/translation/redux";

import Dropdown from "../../components/Dropdown/Dropdown";
import MainPageUpper from "./partials/MainPageUpper/MainPageUpper";
import MainPageFooter from "./partials/MainPageFooter/MainPageFooter";
import MainPageBody from "./partials/MainPageBody/MainPageBody";

const languages = [
  {
    long: "Deutsch",
    short: "DE",
    value: "de",
  },
  {
    long: "English",
    short: "EN",
    value: "en",
  },
  {
    long: "Español",
    short: "ES",
    value: "es",
  },
  {
    long: "Français",
    short: "FR",
    value: "fr",
  },
  {
    long: "Italiano",
    short: "IT",
    value: "it",
  },
];

class MainPage extends React.PureComponent {
  state = {
    device: null,
    resize: true,
  };

  componentWillMount() {
    const device = this.getMobileOperatingSystem();
    window.onresize = () => this.setState({ resize: !this.state.resize });

    this.setState({ device });

    let lang = navigator.language || navigator.userLanguage;

    if (lang) {
      const splittedLang = lang.split("-");
      lang = splittedLang[0];
    }

    if (!lang) {
      lang = "en";
    }

    this.props.setLanguage(lang);
  }

  getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "apple";
    }
    return null;
  };

  handlePress = (val) => {
    this.props.setLanguage(val);
  };

  render() {
    const selected = languages.find((item) => item.value === this.props.lang);

    return (
      <div className="mainPage">
        <Dropdown
          selected={selected}
          device={this.state.device}
          options={languages}
          handlePress={this.handlePress}
          containerStyle="dropdownLanguages"
        />
        <MainPageBody device={this.state.device} />
        <MainPageUpper device={this.state.device} />
        <MainPageFooter device={this.state.device} />
      </div>
    );
  }
}

const mapSelectors = (state, props) => {
  return {
    lang: TranslationSelectors.lang(state),
  };
};

const mapActions = (dispatch) => ({
  setLanguage: (lang) => dispatch(TranslationActions.setLanguage(lang)),
});

export default connect(mapSelectors, mapActions)(MainPage);
