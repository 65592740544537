import React from "react";
import localize from "../../../../redux/translation/localize";
import urlConf from "../../../../Routes/urlConf";

class MainPageUpper extends React.PureComponent {
  handleScrollDown = () => {
    const doc = document.getElementById("mainPageUpper");

    if (doc) {
      window.scrollTo(0, doc.offsetHeight + doc.offsetTop);
    }
  };

  goToLink = (device) => {
    window.open(urlConf[device]);
  };

  getDescription = () => {
    const { t, device } = this.props;

    if (device) {
      return t(
        device === "apple" ? "upperDescriptionIOS" : "upperDescriptionAndroid",
      );
    }
    return t("upperDescription");
  };

  renderStores = () => {
    const { device } = this.props;

    if (!device) {
      return (
        <div className="onlineStores">
          <div
            className="androidStore"
            onClick={() => this.goToLink("android")}
          />
          <div className="appleStore" onClick={() => this.goToLink("apple")} />
        </div>
      );
    }
    return (
      <div className={`onlineStores ${device}`}>
        <div
          className={`${device}Store`}
          onClick={() => this.goToLink(device)}
        />
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const { device } = this.props;

    return (
      <div className="mainPageUpper" id="mainPageUpper">
        <div className="content">
          <div className="info">
            <div className="description">{t("download_app")}</div>
            <div className="description">{t("app_name")}</div>
            <div className={`logoContainer ${device}`}>
              <div className="logo" />
            </div>
            <div className="description">{this.getDescription()}</div>
            {this.renderStores()}
          </div>
          <div className="bodyImage">
            <div className="image" />
          </div>
        </div>
        {/* <div className={`scrollDown ${device}`} onClick={this.handleScrollDown}>
          <div className="text">{t("scrollDown")}</div>
          <div className="icon" />
        </div> */}
      </div>
    );
  }
}

export default localize()(MainPageUpper);
