export default {
  // Header
  headerTitle: "Le monde des marques de parfums Shiseido dans votre poche !",
  headerDescription:
    "Suivez les formations de vos marques favorites, découvrez les dernières tendances et recevez des récompenses en testant vos connaissances !",

  headerComing: " ",

  // Intro
  introDescription:
    "Découvrez Beauty Genius by Shiseido ! Cette application est pensée pour la communauté de conseillères de beauté des marques de parfums de Shiseido :",
  introDescriptionBrands:
    "ISSEY MIYAKE, narciso rodriguez, ZADIG & VOLTAIRE, et Serge Lutens.",
  introDescriptionEnd:
    "Beauty Genius vous permet d’accéder à tout un univers beauté à portée de main.",
  scrollDown: "Faites glisser vers le bas",

  // Upper
  download_app: "Téléchargez l'application",
  app_name: "« Beauty Genius by Shiseido »",
  upperDescription: "sur l’App Store de iOS\n ou le Google Play d’Android.",
  upperDescriptionIOS: "sur l’App Store.",
  upperDescriptionAndroid: "sur le Google Play.",

  // Body
  bodyTitle: "Interagissez avec votre communauté",
  bodyDescription:
    "Posez vos questions à la communauté de Beauty Genius et communiquez avec votre équipe.",
  bodyDescription2: " ",
  bodyFooterDescription: " ",

  // Footer
  footerDescription:
    "Téléchargez l’application ou recherchez « Beauty Genius by Shiseido » sur l’App Store de iOS ou le Google Play d’Android.",
  footerDescriptionIOS:
    "Téléchargez l’application ou recherchez « Beauty Genius by Shiseido » sur l’App Store.",
  footerDescriptionAndroid:
    "Téléchargez l’application ou recherchez « Beauty Genius by Shiseido » sur le Google Play.",
  footerPrivacy:
    "L’application Beauty Genius by Shiseido est exclusivement destinée aux conseillères de beauté travaillant pour les marques de Shiseido. Veuillez contacter votre équipe de formation Shiseido locale pour plus d’information.",

  termsStart: "Beauty Genius ",
  termsPrivacy: "Politique de protection de la vie privé",
  termsBetween: " et ",
  termsUse: "Conditions générales d'accès et d'utilisation",
  termsEnd: ".",

  // Privacy Page
  loading: "Chargement...",
};
