import React from "react";
import { connect } from "react-redux";
import localize from "../../redux/translation/localize";

import { Document, Page, pdfjs } from "react-pdf";
import { actions as TranslationActions } from "../../redux/translation/redux";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PrivacyPage extends React.PureComponent {
  constructor(props) {
    super(props);

    const { origin, pathname } = window.location;
    const lang = pathname.split("/")[1];

    props.setLanguage(lang);

    this.state = {
      values: [],
      pdfFile: `${origin}/assets/privacy/privacy_${lang}.pdf`,
    };
  }

  // Handlers
  handleSuccess = ({ numPages }) => {
    let values = [];

    for (let i = 1; i <= numPages; i++) {
      values.push(
        <Page
          className="privacy_page"
          key={`page_${i}`}
          pageNumber={i}
          loading={null}
        />,
      );
    }
    this.setState({ values });
  };

  handleError = (item) => {
    const { origin } = window.location;

    this.setState({
      pdfFile: `${origin}/assets/privacy/privacy_en.pdf`,
    });
  };

  // Renders
  renderLoading = () => (
    <div className="page_loading">{this.props.t("loading")}</div>
  );

  render() {
    const { values, pdfFile } = this.state;

    return (
      <div className="privacyPage">
        <Document
          file={pdfFile}
          loading={this.renderLoading}
          onLoadSuccess={this.handleSuccess}
          onLoadError={this.handleError}
        >
          {values}
        </Document>
      </div>
    );
  }
}

const mapSelectors = (state, props) => {
  return {};
};

const mapActions = (dispatch) => ({
  setLanguage: (lang) => dispatch(TranslationActions.setLanguage(lang)),
});

export default connect(mapSelectors, mapActions)(localize()(PrivacyPage));
