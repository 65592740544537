export default {
  // Header
  headerTitle: "L’universo dei brand di fragranze Shiseido a portata di mano!",
  headerDescription:
    "Segui i corsi di formazione Shiseido Fragrance per rimanere sempre aggiornato sulle ultime tendenze dei tuoi brand preferiti e vincere fantastici premi!",

  headerComing: " ",

  // Intro
  introDescription:
    "Scopri Beauty Genius by Shiseido. Questa app è disponibile solo per la community di Beauty Consultant che lavorano per i marchi distribuiti da Shiseido Fragrance:",
  introDescriptionBrands:
    "ISSEY MIYAKE, narciso rodriguez, ZADIG & VOLTAIRE, Serge Lutens.",
  introDescriptionEnd:
    "Grazie a Beauty Genius potrai scoprire un mondo di bellezza a tua completa disposizione!",
  scrollDown: "Scorri",

  // Upper
  download_app: "Scarica l'applicazione",
  app_name: "« Beauty Genius by Shiseido »",
  upperDescription: "su App Store per iOS\n o Google Play per Android.",
  upperDescriptionIOS: "su App Store.",
  upperDescriptionAndroid: "su Google Play.",

  // Body
  bodyTitle: "Entra in contatto con la community",
  bodyDescription:
    "Fai domande alla community di Beauty Genius e chatta con il tuo team.",
  bodyDescription2: " ",
  bodyFooterDescription: " ",

  // Footer
  footerDescription:
    "Scarica Beauty Genius by Shiseido su App Store per iOS o Google Play per Android.",
  footerDescriptionIOS: "Scarica Beauty Genius by Shiseido su App Store.",
  footerDescriptionAndroid: "Scarica Beauty Genius by Shiseido su Google Play.",
  footerPrivacy:
    "L’app Beauty Genius by Shiseido è riservata esclusivamente ai Beauty Consultant che lavorano per i brand Shiseido. Rivolgiti al team di formazione Shiseido per saperne di più.",

  termsStart: "Beauty Genius ",
  termsPrivacy: "Informativa sulla Privacy",
  termsBetween: " et ",
  termsUse: "Condizioni Generali di Accesso e di Utilizzo",
  termsEnd: ".",

  // Privacy Page
  loading: "Caricamento in corso...",
};
