export default {
  // Header
  headerTitle:
    "El mundo de las marcas de fragancias de Shiseido en tu bolsillo.",
  headerDescription:
    "Sigue las sesiones de formación de tus marcas favoritas, descubre las últimas tendencias y consigue premios por poner a prueba tus conocimientos.",

  headerComing: " ",

  // Intro
  introDescription:
    "¡Descubre Beauty Genius by Shiseido! Esta aplicación ha sido desarrollada exclusivamente para la comunidad de Asesores de Belleza de las marcas de fragancias Shiseido:",
  introDescriptionBrands:
    "ISSEY MIYAKE, narciso rodriguez, ZADIG&VOLTAIRE y Serge Lutens.",
  introDescriptionEnd:
    "Beauty Genius es la llave que te abrirá la puerta a un universo de belleza al alcance de tu mano.",
  scrollDown: "Deslizar hacia abajo",

  // Upper
  download_app: "Descarga la aplicación",
  app_name: "« Beauty Genius by Shiseido »",
  upperDescription:
    "en la App Store para dispositivos iOS\n o en la Google Play para Android.",
  upperDescriptionIOS: "en la App Store",
  upperDescriptionAndroid: "en la Google Play.",

  // Body
  bodyTitle: "Interactua con Tu Comunidad",
  bodyDescription:
    "Plantea preguntas a la comunidad Beauty Genius y chatea con tu Equipo.",
  bodyDescription2: " ",
  bodyFooterDescription: " ",

  // Footer
  footerDescription:
    "Busca y descarga Beauty Genius by Shiseido en la App Store para dispositivos iOS o en la Google Play para Android.",
  footerDescriptionIOS:
    "Busca y descarga Beauty Genius by Shiseido en la App Store.",
  footerDescriptionAndroid:
    "Busca y descarga Beauty Genius by Shiseido en la Google Play.",
  footerPrivacy:
    "La aplicación Beauty Genius by Shiseido está pensada para los Asesores de Belleza que trabajan exclusivamente con las marcas Shiseido. Si quieres obtener más información, ponte en contacto con tu equipo local de formadores Shiseido.",

  termsStart: "Beauty Genius ",
  termsPrivacy: "Aviso de Privacidad",
  termsBetween: " y ",
  termsUse: "las Condiciones Generales de Acceso y Uso",
  termsEnd: ".",

  // Privacy Page
  loading: "Cargando...",
};
