import MainPage from "../pages/MainPage/MainPage";
import PrivacyPage from "../pages/PrivacyPage/PrivacyPage";
import TermsOfUsePage from "../pages/TermsOfUsePage/TermsOfUsePage";

const routes = {
  HOME: "/",
  PRIVACY: "/:lang/privacy",
  TERMS_OF_USE: "/:lang/terms_of_use",
};

const config = [
  {
    path: routes.HOME,
    exact: true,
    component: MainPage,
  },
  {
    path: routes.PRIVACY,
    exact: true,
    component: PrivacyPage,
  },
  {
    path: routes.TERMS_OF_USE,
    exact: true,
    component: TermsOfUsePage,
  },
];

export default routes;
export { config };
